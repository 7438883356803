import { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Pagination,
  TableContainer,
  TextField,
} from "@mui/material";
import { useQuery } from "react-query";
import { getAllHistory } from "../../api/calls-history/calls-history";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { api } from "../../api/Intercepter";
import ClientsAutoComplete from "../phone-book/components/ClientsAutoComplete";
import HistoryAutoComplete from "./history-auto-complete/history-auto-complete";
import { CustomTextField } from "../../components/inputs/input/BootStrapInput";
import DateRangePickerMui from "../../components/inputs/date-range-picker-mui/DateRangePickerMui";
import BasicRangeShortcuts from "../../components/inputs/date-range-picker-mui/CustomShortcutItem";
// import ClientsAutoComplete from "./components/ClientsAutoComplete";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Fade, Menu } from "@mui/material";
import { StyledTextarea } from "../../components/inputs/custom-text-area/custom-text-area";
import { formatDateAndTime } from "../../utils/formatDateAndTime";
import { convertArabicToEnglishNumber } from "../../utils/convertArabicToEnglish";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  border: "none",
  borderRadius: "6px",
};

const INITIAL_DATE_RANGE: DateRange<Dayjs> = [
  dayjs().subtract(1, "day").add(3, "hours"),
  dayjs().add(3, "hours"),
];

const History = () => {
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const limit: number = 100;

  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [editedFullName, setEditedFullName] = useState("");
  const [editedAutoComplete, setEditedAutoComplete] = useState<any>();
  const [editedNotes, setEditedNotes] = useState("");
  const [clientHistoryId, setClientHistoryId] = useState("");
  const [startDate, setStartDate] = useState<any>(
    INITIAL_DATE_RANGE[0].toISOString()
  );
  const [endDate, setEndDate] = useState<any>(
    INITIAL_DATE_RANGE[1].toISOString()
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openDateRange = Boolean(anchorEl);

  const handleSearch = (value) => {
    let cleanedPhoneNumber = convertArabicToEnglishNumber(value ?? 0)?.replace(
      /^07/,
      "7"
    );
    console.log(cleanedPhoneNumber.replace(/^(\+964|964)/, ""));

    return cleanedPhoneNumber.replace(/^(\+964|964)/, "");
  };

  const { data: historyData, refetch } = useQuery({
    queryKey: [
      `history-page-${page}-limit-${limit}-${search}-${startDate}-${endDate}`,
    ],
    queryFn: async () => {
      const data = await getAllHistory({
        currentPage: page,
        limit: limit,
        search: handleSearch(search),
        startDate: startDate,
        endDate: endDate,
      });
      return data;
    },
    refetchOnWindowFocus: false,
    staleTime: 10 * 60 * 1000,
  });

  useEffect(() => {
    refetch();
  }, []);

  const handleDoubleClick = (index, fullName, notes) => {
    setEditingRowIndex(index);
    setEditedFullName(fullName);
  };

  const handleFullNameChange = (e) => {
    setEditedFullName(e.target.value);
  };

  const handleNotesChange = (e) => {
    setEditedNotes(e.target.value);
  };

  const handleSubmitData = (index, userId) => {
    api
      .patch(`${import.meta.env.VITE_SOME_KEY_BASE_URL}/clients/${userId}`, {
        fullName: editedFullName,
        neighborhoodId: editedAutoComplete.neighborhood_id ?? undefined,
        cityId: editedAutoComplete.city_id ?? undefined,
        subCityId: editedAutoComplete.sub_city_id ?? undefined,
        governorateId: editedAutoComplete.governorate_id ?? undefined,
      })
      .then(() => {
        refetch();
      });

    setEditedAutoComplete("");
    setEditingRowIndex(null);
    setEditedFullName("");
  };

  const handleSubmitNotes = () => {
    api
      .patch(
        `${import.meta.env.VITE_SOME_KEY_BASE_URL}/calls/${clientHistoryId}`,
        {
          notes: editedNotes,
        }
      )
      .then((response) => {
        refetch();
      });

    setClientHistoryId("");
    handleCloseNotesModal();
  };

  function formatTime(seconds) {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedTime = `${minutes}:${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}`;

    return formattedTime;
  }

  const handleDateRange = (e: any) => {
    setStartDate(e[0]?.toISOString());
    setEndDate(e[1]?.toISOString());
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openNotesModal, setOpenNotesModal] = useState(false);
  const handleCloseNotesModal = () => setOpenNotesModal(false);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#F2F5FA",
        padding: "20px 40px",
      }}
    >
      <Box sx={{ display: "flex", gap: "10px" }}>
        <CustomTextField
          placeholder="البحث"
          onChange={(e) => setSearch(e.target.value)}
          sx={{
            backgroundColor: "white",
          }}
        />
        <Box sx={{ width: "fit-content" }}>
          <DateRangePickerMui
            startDate={startDate}
            endDate={endDate}
            handleClick={handleClick}
            style={{ backgroundColor: "white" }}
          />
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={openDateRange}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <BasicRangeShortcuts handleDateRange={handleDateRange} />
          </Menu>
        </Box>
      </Box>
      <div style={{ backgroundColor: "white", borderRadius: "12px" }}>
        <div style={{ width: "100%", marginTop: "20px" }}>
          <TableContainer
            sx={{
              width: "100%",
              backgroundColor: "white",
              paddingBottom: "20px",
              borderRadius: "6px",
            }}
          >
            <Table
              sx={{ minWidth: 650, "*": { fontSize: "14px" } }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#DEE6FA" }}>
                <TableRow>
                  <TableCell>الاسم</TableCell>
                  <TableCell>رقم الهاتف</TableCell>
                  <TableCell>القضاء</TableCell>
                  <TableCell>المنطقة</TableCell>
                  <TableCell>وقت الاتصال</TableCell>
                  <TableCell>مدة الاتصال</TableCell>
                  <TableCell>البرنامج</TableCell>
                  <TableCell>عدد المكالمات</TableCell>
                  <TableCell>مللاحظات</TableCell>
                  <TableCell>تعديل</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ "*": { fontSize: "14px !important" } }}>
                {historyData?.array?.map((row, index) => (
                  <TableRow
                    key={row?.id}
                    onDoubleClick={() =>
                      handleDoubleClick(index, row.fullname, row.notes)
                    }
                  >
                    <TableCell
                      sx={{
                        width: "20%",
                        color:
                          row.reason === "IGNORE" ||
                          row.reason === "NOT ANSWERED"
                            ? "red"
                            : "black",
                      }}
                    >
                      {editingRowIndex === index ? (
                        <TextField
                          type="text"
                          value={editedFullName}
                          onChange={handleFullNameChange}
                          size="small"
                        />
                      ) : (
                        <span>{row?.fullname}</span>
                      )}
                    </TableCell>

                    <TableCell sx={{ width: "0%", direction: "rtl" }}>
                      {row?.phonenumber}
                    </TableCell>
                    <TableCell>
                      {historyData?.locations[row?.id]?.city_name ?? "لا يوجد"}
                    </TableCell>
                    <TableCell sx={{ width: "30%" }}>
                      {editingRowIndex === index ? (
                        <HistoryAutoComplete
                          data={row}
                          editedAutoComplete={editedAutoComplete}
                          setEditedAutoComplete={setEditedAutoComplete}
                          locations={historyData?.locations}
                        />
                      ) : (
                        <>
                          {historyData?.locations[row?.id]?.neighborhood_name ??
                            historyData?.locations[row?.id]?.sub_city_name ??
                            historyData?.locations[row?.id]?.city_name ??
                            "لا يوجد"}
                        </>
                      )}
                    </TableCell>
                    <TableCell sx={{ width: "15%" }}>
                      {formatDateAndTime(row?.startdate)}
                    </TableCell>
                    <TableCell sx={{ width: "0%" }}>
                      {formatTime(row?.duration)}
                    </TableCell>
                    <TableCell sx={{ width: "10%" }}>
                      {row?.radioshowname}
                    </TableCell>
                    <TableCell sx={{ width: "5%" }}>{row?.callcount}</TableCell>
                    <TableCell
                      sx={{ width: "100%" }}
                      onDoubleClick={() => {
                        setEditedNotes(row?.notes);
                        setClientHistoryId(row?.callid);
                        setOpenNotesModal(true);
                      }}
                    >
                      {row.notes}
                    </TableCell>
                    {editingRowIndex === index ? (
                      <TableCell sx={{ width: "0px" }}>
                        <Button
                          onClick={() => handleSubmitData(index, row?.clientid)}
                        >
                          حفظ
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            handleDoubleClick(index, row.fullname, row.notes)
                          }
                        >
                          <EditOutlinedIcon
                            sx={{ fontSize: "20px !important" }}
                          />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              sx={{ marginTop: "10px", marginLeft: "20px" }}
              count={Math.ceil(historyData?.count / limit)}
              onChange={(e, value) => setPage(value - 1)}
              variant="outlined"
              shape="rounded"
            />
          </TableContainer>
        </div>
      </div>
      <Modal
        open={openNotesModal}
        onClose={handleCloseNotesModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <StyledTextarea
            value={editedNotes}
            onChange={handleNotesChange}
            placeholder="ملاحظات"
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button
              sx={{
                width: "100%",
                background: "white",
                fontSize: "16px",
                padding: "6px 40px",
                color: "white",
                borderRadius: "6px",
                backgroundColor: "#629FC1",
                "&:hover": {
                  backgroundColor: "#629FC1",
                  color: "white",
                },
              }}
              onClick={() => handleSubmitNotes()}
            >
              حفظ
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default History;
