import React from "react";
import { Box, Button, Divider, Grid, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Socket, io } from "socket.io-client";
import { useLocation } from "react-router-dom";
import Callers from "../callers/Callers";
import History from "../history/History";
import RegisterUsers from "../register-users/RegisterUsers";
import PhoneBook from "../phone-book/PhoneBook";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface FormInput {
  name: string;
  city?: string;
  phone: string;
  gender: string;
  info: string | undefined;
}

interface ContextForms {
  form1: FormInput;
  form2: FormInput;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Home = () => {
  const [value, setValue] = useState(() => {
    return Number(localStorage.getItem("selectedTab")) || 0;
  });
  const [calls, setCalls] = useState([]);
  const [lastMessageTime, setLastMessageTime] = useState(0);
  const methods = useForm<ContextForms>();
  const methods2 = useForm<ContextForms>();
  const methods3 = useForm<ContextForms>();
  const methods4 = useForm<ContextForms>();
  const methods5 = useForm<ContextForms>();
  const methods6 = useForm<ContextForms>();
  const methods7 = useForm<ContextForms>();
  const methods8 = useForm<ContextForms>();
  const methods9 = useForm<ContextForms>();
  const methods10 = useForm<ContextForms>();
  const methods11 = useForm<ContextForms>();
  const methods12 = useForm<ContextForms>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    localStorage.setItem("selectedTab", String(newValue));
    setValue(newValue);
  };

  useEffect(() => {
    const socket: Socket<any> = io("https://phone.ticketin.net");

    let eventTimer: NodeJS.Timeout | null;

    socket.on("event", (data: any) => {
      setCalls(data);
      setLastMessageTime(Date.now());
      if (eventTimer) {
        clearTimeout(eventTimer);
      }

      eventTimer = setTimeout(() => {
        setCalls([]);
      }, 2000);
    });
    return () => {
      if (eventTimer) {
        clearTimeout(eventTimer);
      }
      socket.disconnect();
    };
  }, []);

  const logout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          position: "relative",
        }}
      >
        <Button
          sx={{
            position: "absolute",
            right: "20px",
            top: "13px",
            zIndex: 99,
            padding: "5px 10px",
            border: "1px solid #475D92",
            color: "#475D92",
          }}
          onClick={() => logout()}
        >
          تسجيل خروج
        </Button>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            ".MuiTabs-indicator": { display: "none" },
            ".MuiTabs-flexContainer": { height: "65px" },
          }}
        >
          <Tab sx={{ fontSize: "18px" }} label="المتصلين" {...a11yProps(0)} />
          <Tab
            sx={{ fontSize: "18px" }}
            label="سجل المكالمات"
            {...a11yProps(1)}
          />
          <Tab
            sx={{ fontSize: "18px" }}
            label="تسجيل المتصلين"
            {...a11yProps(2)}
          />
          <Tab
            sx={{ fontSize: "18px" }}
            label="ارقام الهواتف"
            {...a11yProps(3)}
          />
        </Tabs>
        <Divider />
        <CustomTabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Callers
                methods={methods}
                methods2={methods2}
                methods3={methods3}
                methods4={methods4}
                methods5={methods5}
                methods6={methods6}
                methods7={methods7}
                methods8={methods8}
                methods9={methods9}
                methods10={methods10}
                methods11={methods11}
                methods12={methods12}
                calls={calls}
              />
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <History />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <RegisterUsers />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <PhoneBook />
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default Home;
