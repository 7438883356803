import { api } from "../Intercepter";

interface GetAllPhoneBookParams {
  currentPage: number;
  limit: number;
  search: string;
}

export const getAllPhoneBook = async ({
  currentPage,
  limit,
  search,
}: GetAllPhoneBookParams) => {
  let response = await api.get<GetAllPhoneBookResponse>(
    `/clients?relations[]=phones`,
    {
      params: {
        offset: currentPage * limit,
        limit: limit,
        q: search,
      },
    }
  );

  if (!response) {
    throw new Error("Error in getting all phone book");
  }

  return response.data as GetAllPhoneBookResponse;
};

export interface GetAllPhoneBookResponse {
  array: User[];
  locations: any[];
  count: number;
}

export interface User {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  fullName: string;
  email: any;
  notes: string;
  neighborhoodId: any;
  subCityId: any;
  cityId: any;
  gender?: string;
  isBlcoked: boolean;
  phones: Phone[];
  region?: Region;
  city?: City;
  location: any;
  appClient: any;
}

export interface Phone {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  phoneNumber: string;
  clientId: string;
}

export interface Region {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  cityId: string;
  regionName: string;
}

export interface City {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  governorateId: string;
  cityName: string;
}
