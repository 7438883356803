import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { api } from "../../../api/Intercepter";

// {
//     "id": "500f8292-2b67-4333-b2ff-65a703c1c199",
//     "createdAt": "2023-08-13T14:38:13.508Z",
//     "updatedAt": "2023-08-30T12:32:49.503Z",
//     "deletedAt": null,
//     "fullName": "رقم قاااااااف",
//     "email": null,
//     "notes": "بس تجربة",
//     "sub_cityId": "dfec8b66-cdef-4d99-a023-d87e997333f5",
//     "cityId": null,
//     "gender": "male",
//     "isBlocked": false,
//     "phones": [],
//     "sub_city": {
//         "id": "dfec8b66-cdef-4d99-a023-d87e997333f5",
//         "createdAt": "2023-08-13T06:09:52.230Z",
//         "updatedAt": "2023-08-13T06:09:52.230Z",
//         "deletedAt": null,
//         "cityId": "aaef955a-787e-4b63-8e34-44ed51ba8e5a",
//         "sub_cityName": "حي الزراعي"
//     },
//     "city": null
// },

const ClientsAutoComplete = ({
  data,
  editedAutoComplete,
  setEditedAutoComplete,
  locations,
}) => {
  const [options, setOptions] = useState([]);

  const handleSteps = (value: any) => {
    api
      .get(
        `${
          import.meta.env.VITE_SOME_KEY_BASE_URL_LOCATION_MAKER
        }/auto-complete/${value}`
      )
      .then((response) => {
        if (response.status === 200) {
          setOptions(response?.data);
        }
      });
  };

  useEffect(() => {
    // const structureLocation = {
    //   city_id: data?.sub_city?.cityId,
    //   city_name: data?.city,
    //   governorate_name: undefined,
    //   sub_city_id: data?.sub_city?.id,
    //   sub_city_name: data?.sub_city?.sub_cityName,
    // };
    const structureLocation = {
      city_id: locations[data?.id]?.city_id,
      city_name: locations[data?.id]?.city_name,
      governorate_name: locations[data?.id]?.governorate_name,
      governorate_id: locations[data?.id]?.governorate_id,
      sub_city_id: locations[data?.id]?.sub_city_id,
      sub_city_name: locations[data?.id]?.sub_city_name,
      neighborhood_id: locations[data?.id]?.neighborhood_id,
      neighborhood_name: locations[data?.id]?.neighborhood_name,
    };

    setEditedAutoComplete(structureLocation);
  }, [data]);

  function formatLocation(...locationComponents) {
    const nonEmptyComponents = locationComponents.filter(
      (component) => component
    )?.reverse();

    return nonEmptyComponents.join(" / ");
  }

  function getOptionLabel(option) {
    let governorate_name = option?.governorate_name;
    let city_name = option?.city_name;
    let sub_city_name = option?.sub_city_name;
    let neighborhood_name = option?.neighborhood_name;

    const formattedLocation = formatLocation(
      governorate_name,
      city_name,
      sub_city_name,
      neighborhood_name
    );

    return formattedLocation;
  }

  return (
    <div>
      <Autocomplete
        sx={{ minWidth: "250px" }}
        size="small"
        disablePortal
        options={options}
        value={editedAutoComplete ?? ""}
        onChange={(_, newValue) => {
          handleSteps(newValue);
          setEditedAutoComplete(newValue);
        }}
        onInputChange={(_, newValue) => {
          handleSteps(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="المدينة"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        getOptionLabel={(option) => getOptionLabel(option)}
      />
    </div>
  );
};

export default ClientsAutoComplete;
